<template>
  <b-card>
    <b-link :to="{ name: 'arena-category-detail', params: { category_id: category._id } }">
      <b-img class="w-100" :src="category.thumbnail.value" v-if="category.thumbnail.value" />
      <b-img v-else :blank="true" blank-color="#ccc" alt="placeholder" height="171" class="w-100" />
      <h3 class="mt-1 mb-0 text-truncate-2" style="height: 2.7em">{{ category.title || category._id }} </h3>
      <small class="d-block mt-50" style="color: #bbb">
        Last edited: {{ category.updatedAt | local_date }}
      </small>
    </b-link>
  </b-card>
</template>

<script>
export default {
  props: {
    category: { type: Object, required: true },
  },
}
</script>
