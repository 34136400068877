<template>
  <div>
    <div class="d-flex justify-content-between mt-3">
      <h2 class="h2">Category List</h2>
      <div class="d-flex ">
        <b-button variant="warning" :disabled="!is_order_changed" @click="updateOrder">
          <feather-icon
            icon="CheckCircleIcon"
            class="mr-50"
          />
          <span class="align-middle">Update Display Order</span>
        </b-button>
        <category-create-form class="ml-50" :display_order="list.length - 1" />
      </div>
    </div>
    <b-row class="mt-1">
      <draggable v-model="list" class="w-100"
        handle=".grab-item"
      >
        <transition-group type="transition">
          <b-col cols="4" v-for="(category) in list" :key="category._id" class="grab-item">
            <category-card :category="category" />
          </b-col>
        </transition-group>
      </draggable>
    </b-row>
  </div>
</template>

<script>
import CategoryCreateForm from './_components/CategoryCreateForm.vue'
import CategoryCard from './_components/CategoryCard.vue'
import draggable from 'vuedraggable'

import service from '../service'

export default {
  components: {
    CategoryCreateForm,
    CategoryCard,
    draggable,
  },
  data() {
    return {
      list: [],
      total_page: 0,
      is_order_changed: false,
    }
  },
  watch: {
    list(value, old_value) {
      if (!old_value.length) { return }
      if (this.is_order_changed === false) {
        this.is_order_changed = true
      }
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      let data = await service.getList({ order_by: 'display_order', order: 1 })
      if (!data) { return }
      this.list = data.list
      this.total_page = data.total_page
    },
    async updateOrder() {
      let list = this.list.map(item => { return { _id: item._id, display_order: item.display_order } })
      let response = await service.updateOrder({ list })
      if (response.data.success) {
        this.is_order_changed = false
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully update lessons order!' })
      }
    },
  },
}
</script>

<style>
.grab-item {
  transition: ease 0.6s
}

.row > div > span{
  display: flex;
  width: 100%;
  clear: both;
  flex-flow: wrap;
}
</style>
