<template>
  <div>
    <b-button variant="primary" @click="is_modal_open = true">
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Add new</span>
    </b-button>
    <b-modal ok-only ok-title="Create" centered title="Create Category" v-model="is_modal_open"
      @ok="createDocument" :ok-disabled="!category_key"
    >
      <strong class="h6">Slug</strong>
      <b-form-input class="mb-3" type="text" v-model="category_key" placeholder="category_abc_xyz" />
    </b-modal>
  </div>
</template>

<script>
import service from '../../service'

export default {
  props: {
    display_order: { type: Number, required: true },
  },
  data() {
    return {
      is_modal_open: false,
      category_key: null,
    }
  },
  methods: {
    async createDocument() {
      let new_document = await service.create({ key: this.category_key, display_order: this.display_order })
      if (new_document) {
        this.$router.push({ name: 'arena-category-detail', params: { category_id: new_document._id } })
      }
    },
  },
}
</script>
